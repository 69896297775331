@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

:root {
  --vh: 1vh;
  height: 100vh;
}

html {
  min-height: 100vh;
}

.Toastify__toast-body {
  @apply yep_ex-font-medium yep_ex-text-sm yep_ex-text-gray-900;
}


#headlessui-portal-root{
  position: relative;
  z-index: 999999;
}
